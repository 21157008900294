export interface OceanRoutesEntity {
    _id: string;
    origin: string;
    destination: string;
    routes: OceanRoute[];
}

export interface OceanRoute {
    suez: boolean;
    panama: boolean;
    distance: number;
    path: {
        type: string;
        coordinates: [number, number][];
    };
}
