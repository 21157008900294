import { Commodity, Packaging, RateCalculation } from '../masterdata';
import { UserSummary } from '../auth';
import { ShipmentSummary } from './Shipments';
import { PaginationMeta } from '../common';

export interface QuotationPackage {
    id: number;
    quantity: number;
    packaging: Packaging;
    unit_weight: number | null;
    unit_volume: number | null;
    unit_length: number | null;
    unit_width: number | null;
    unit_height: number | null;
    uom: 'imperial' | 'metric';
    total_weight_kgs: number;
    total_volume_cbm: number;
    total_chargeable_weight_kgs: number;
    container_type_code: string;
    container_type_name: string;
}

export interface QuotationRequest {
    owner_reference: string | null;
    origin: {
        type: string;
        code: string;
        locode: string;
        name: string;
        country_code: string;
    };
    destination: {
        type: string;
        code: string;
        locode: string;
        name: string;
        country_code: string;
    };
    transport_mode: string;
    load_type: string;
    require_origin_charges: boolean;
    require_freight_charges: boolean;
    require_destination_charges: boolean;
    cargo_ready: Date | null;
    inco_term: string | null;
    commodity: Commodity;
    currency: string;
    additionals: any;
    packages: QuotationPackage[];
    total_quantity: number;
    total_weight_kgs: number;
    total_volume_cbm: number;
    total_chargeable_weight_kgs: number;
    created_at?: Date;
}

export interface QuotationOffer {
    id: string;
    quotation_id: string;
    carrier: {
        scac: string;
        name: string;
        qwykschedules_code: string;
    } | null;
    carrier_name: string;
    commodity: string | null;
    service: string | null;
    pol: any;
    pol_via: any;
    pod: any;
    pod_via: any;
    transport_mode: string;
    load_type: string;
    rate_name: string;
    rate_source_name?: string;
    transit_time: number | null;
    currency: string;
    total_amount: number;
    total_margin?: number | null;
    total_buying?: number | null;
    breakdown: QuotationOfferLine[];
    grouped_breakdown: {
        group: string;
        lines: QuotationOfferLine[];
        sort: number;
        total: number;
    }[];
    valid_from: Date | null;
    valid_until: Date | null;
    remarks: string | null;
    created_at: Date;
    offer_lines?: QuotationOfferLine[];
    shipment_ids?: number[];
    leeway_days?: number;
    service_level: string | null;
}

export interface QuotationOfferLine {
    id: number;
    segment: string;
    charge_code: string | object;
    charge_description: string;
    base_currency: string;
    base_rate: number;
    base_calculation: RateCalculation;
    base_minimum: string;
    total_amount: number;
    exchange_rate: number;
    total_amount_quotation: number;
    margin_amount?: number | null;
    total_buy_amount_quotation?: number | null;
    remarks: string | null;
}

export interface QuotationEntity {
    id: string;
    friendly_id: null | string;
    owner: UserSummary;
    creator: UserSummary;
    status: string;
    request: QuotationRequest;
    results: QuotationOffer[] | WrappedQuotationOffers;
    last_rate_request: QuotationRateRequest | null;
    rate_requests: QuotationRateRequest[];
    booked_shipments: ShipmentSummary[];
    shipments: ShipmentSummary[];

    temp_token?: string;
    is_demo: boolean;
    is_owner: boolean;
    is_shared_with: boolean;
    shared_users: UserSummary[];
    is_draft: boolean;
    offers_count?: number;
    shipments_count?: number;
    booked_shipments_count?: number;
    open_rate_requests_count?: number;
    is_network_quotation?: boolean;
}

export interface WrappedQuotationOffers {
    data: QuotationOffer[];
    filters: {
        total_amount: {
            min: number;
            max: number;
        };
        transit_time: {
            min: number;
            max: number;
        };
        carriers: {
            known: boolean;
            key: string;
            name: string;
        }[];
        pols: {
            locode: string;
            name: string;
        }[];
        pods: {
            locode: string;
            name: string;
        }[];
        total_results: number;
    };
    meta: PaginationMeta;
}

export interface QuotationRateRequest {
    id: string;
    quotation_id: string;
    creator: UserSummary;
    open: boolean;
    reason: string;
    remarks: string | null;
    responder: UserSummary | null;
    response_message: string | null;
    offer: QuotationOffer | null;
    created_at: Date;
    updated_at: Date;
}
