import { UserSummary } from '../auth/';

/**
 * Interface for the 'Conversations' data
 */
export interface Conversation {
    _id: string; // Primary ID
    customer: UserSummary;
    participants: UserSummary[];
    subject: string;
    created_at: Date;
    updated_at: Date;
    messages: ConversationMessage[];
}

export interface ConversationMessage {
    _id: string;
    sender: UserSummary;
    message: string;
    type: string;
    created_at: Date;
    attachment?: {
        filename: string;
        file_size_bytes: number;
        file_disk: string;
        storage_key: string;
        mime_type: string;
        extension: string;
    } | null;
}
