import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'traverse',
})
export class TraversePipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any, ...args: unknown[]): unknown {
        if (!value) {
            return value;
        }
        const tree = [...(args[0] as string[])];
        return this.traverse(value, tree);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    traverse(value: { [x: string]: any }, tree: string[]): any {
        if (!value) {
            return value;
        }

        if (!tree || tree.length === 0) {
            return value;
        }

        if (typeof value !== 'object') {
            return value;
        }

        const next = tree.splice(0, 1)[0];
        return this.traverse(value[next], tree);
    }
}
