import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

export interface QueryBuilderToken {
    key: string;
    label: string;
    icon: [IconPrefix, IconName];
    exclusive: boolean;
}

export interface QueryBuilderQuery {
    attribute: {
        key: string;
        label: string;
        fulltext?: boolean;
    };
    value: {
        key: string;
        label?: string | null;
    } | null;
}

export interface QueryBuilderSortOption {
    key: string;
    label: string;
    direction: 'asc' | 'desc';
    allowChangeDirection?: boolean;
}
