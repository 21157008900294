export interface Milestone {
    'id': number;
    'active': boolean;
    'default': boolean;
    'products': string[];
    'transport_modes': string[];
    'event_sort': number;
    'event_key': string;
    'event_icon': string;
    'event_expected_label': string;
    'event_actual_label': string;
    'event_expected_timestamp_path': Date|string;
    'event_expected_tz_path': string;
    'expected_location_locode_path': string;
    'expected_location_position_path': string;
    'updated_at': Date|string;
}
