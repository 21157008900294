import { RouterLink } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { ShipmentQuotation } from '@qwyk/shared-stores/shipment-relations';

@Component({
    standalone: true,
    imports: [RouterLink, CurrencyPipe, TranslateModule],
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-shipment-quotation-item]',
    templateUrl: './shipment-quotation-item.component.html',
    styleUrls: ['./shipment-quotation-item.component.scss'],
})
export class ShipmentQuotationItemComponent {
    @Input() quotation: ShipmentQuotation;
}
