import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '@qwyk/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';

@Pipe({
    standalone: true,
    name: 'loadTypeIcon',
})
export class LoadTypeIconPipe implements PipeTransform {
    private _icons: any = Constants.LOAD_TYPE_ICONS;

    transform(value: string): IconName {
        return this._icons[value] as IconName;
    }
}
