import { Pipe, PipeTransform } from '@angular/core';

import { Constants } from '@qwyk/core';

@Pipe({
    standalone: true,
    name: 'productColor',
})
export class ProductColorPipe implements PipeTransform {
    private readonly _colors: { [index: string]: string } =
        Constants.PRODUCT_COLORS;

    transform(value: string): string {
        return this._colors[value];
    }
}
