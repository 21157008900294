import { Address } from '../common';

export interface PurchaseOrdersEntity {
    load_type: 'fcl' | 'lcl';
    id: string;
    owner_id: string;
    organization_id: number;
    po_number: string;
    status: string;
    order_date: null;
    latest_delivery_date: null;
    earliest_delivery_date: null;
    currency: string;
    inco_term: string;
    inco_term_named_place: null;
    inspection_required: boolean;
    inspection_passed_date: null;
    payment_term: string;
    payment_term_description: string;
    mode: null;
    planned_equipment_type: null;
    supplier: Address;
    supplier_order_number: null;
    buyer: Address;
    ship_to: Address;
    instructions: null;
    terms_and_conditions: null;
    lines: PurchaseOrderLinesEntity[];
    created_at: string;
    updated_at: string;
    owner: {
        name: string;
    };
    shipments: []
}

export interface PurchaseOrderLinesEntity {
    id: number;
    order_id: string;
    line_no: number;
    status: string;
    quantity_requested: number;
    units_per_pack: number;
    unit_price: number;
    product_name: string;
    product_description: string;
    product_category_code: null;
    product_category_description: null;
    color_code: null;
    color_description: null;
    size_code: null;
    size_description: null;
    customer_product_id: null;
    customer_sku: null;
    sku_description: null;
    upc: null;
    total_packages: number;
    unit_of_measure_description: null;
    marks_and_numbers: null;
    gross_weight_kgs: null;
    volume_cbms: null;
    port_of_loading: null;
    port_of_discharge: null;
    ship_to: Address;
    shipment: any;
    po_number: string;
    mode: string;
    currency: string;
    load_type: string;
    shipment_data_packages: PurchaseOrderLineShipmentDataPackage[]
    can_book : boolean;
}

export interface PurchaseOrderLineShipmentDataPackage{
    id: number;
    packaging: string;
    package_quantity: number;
    shipment_id: string;
    shipment_name:string;
    shipment_sys_state: string;
}
