import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

import * as tz from 'moment-timezone';
import { MomentModule } from 'ngx-moment';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Portals } from '@qwyk/models';

import { LineBreaksPipe, ValueMapPipe } from '../../pipes';

@Component({
    standalone: true,
    imports: [NgIf, NgClass, LineBreaksPipe, ValueMapPipe, MomentModule, FontAwesomeModule, NgbTooltipModule],
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-shipment-tracking-events-list-item]',
    templateUrl: './shipment-tracking-events-list-item.component.html',
    styleUrls: ['./shipment-tracking-events-list-item.component.scss'],
})
export class ShipmentTrackingEventsListItemComponent {
    @Input() event: Portals.ShipmentTrackingEvent;
    @Input() showLocalTime = true;

    public readonly EVENT_CODE_ICONS = {
        'origin.emptyout': 'sign-out',
        'origin.fullin': 'sign-in',
        'origin.loaded': 'arrow-from-bottom',
        'origin.departed': 'ship',
        'hub1.arrived': 'anchor',
        'hub1.unloaded': 'arrow-to-bottom',
        'hub1.loaded': 'arrow-from-bottom',
        'hub1.departed': 'ship',
        'destination.arrived': 'anchor',
        'destination.unloaded': 'arrow-to-bottom',
        'destination.fullout': 'sign-out',
        'destination.emptyin': 'sign-in',
        rail_departed: 'train',
        generic: 'radar',
        tracking_request_succeeded: 'radar',
        tracking_request: 'radar',
        tracking_request_failed: 'engine-warning',
        container_updated: 'container-storage',
        shipment_eta_updated: 'clock',
    };

    public userTimezone;

    constructor() {
        this.userTimezone = tz.tz(tz.tz.guess());
    }
}
