export interface UserProfile {
    id: string;
    name: string;
    email: string;
    organization_id: number;
    location: string | null;
    company_name: string | null;
    job_title: string | null;
    phone_number: string | null;
    language: string;
    units_of_measure: string;
    timezone: string;
    country: {
        alpha_2_code: string;
        alpha_3_code: string;
        english_short_name: string;
        short_name: string;
    } | null;
}
