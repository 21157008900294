import { UserSummary } from '../auth';

export interface SchedulesSearchQuery {
    origin: {
        locode: string;
        display_name: string;
        position?: any;
    };
    destination: {
        locode: string;
        display_name: string;
        position?: any;
    };
    product: string;
}

export interface ScheduleCarrier {
    code: string;
    display_name: string;
}

export interface Schedule {
    id?: string;
    carrier: ScheduleCarrier;
    vessel: string;
    vessel_info: { vessel_name: string; imo: string; mmsi: string };
    voyage: string;
    closing: Date;
    closing_cargo: Date;
    closing_doc: Date;
    closing_imo: Date;
    etd: Date;
    eta: Date;
    origin: string;
    origin_info: any;
    origin_is_precarriage: boolean;
    origin_via: string;
    destination: string;
    destination_info: any;
    destination_via?: any;
    legs: ScheduleLeg[];
    service: string;
    service_mode: string;
    notes?: any;
    product: 'air' | 'lcl' | 'fcl';
    daysOfOperation: string;
    ocean_carrier: string;
    schedule_notes: string;
    type: string;
}

export interface ScheduleLeg {
    id: number;
    ident: string;
    vessel?: string;
    voyage?: string;
    carrier: ScheduleCarrier;
    origin: string;
    destination: string;
    etd: Date;
    eta?: Date;
    notes?: any;
    vessel_info?: any;
    service: string;
    type: string;
}

export interface SavedScheduleLocation {
    locode: string;
    name: string;
    country_name: string;
    display_name: string;
    position: [number, number];
    timezone?: string;
}
export interface SavedScheduleLeg {
    id: number;
    ident: string;
    carrier: ScheduleCarrier;
    origin: SavedScheduleLocation | null;
    destination: SavedScheduleLocation | null;
    etd: Date;
    eta?: Date;
    notes?: any;
    vessel_info?: any;
    service: string;
    type: string;
}

export interface SavedSchedule {
    id: string;
    owner: UserSummary;
    product: 'air' | 'lcl' | 'fcl';
    carrier: ScheduleCarrier;
    vessel: string;
    voyage: string;
    closing?: Date;
    closing_cargo?: Date;
    closing_doc?: Date;
    closing_imo?: Date;
    etd: Date;
    eta: Date;
    origin: SavedScheduleLocation;
    origin_via?: SavedScheduleLocation;
    destination: SavedScheduleLocation;
    destination_via?: SavedScheduleLocation;
    service: string;
    notes?: string;
    legs: SavedScheduleLeg[];
    created_at: Date;
    updated_at: Date;
    available: boolean;
    is_owner: boolean;
    is_shared_with: boolean;
    shared_users: UserSummary[];
}

export interface SavedScheduleSummary {
    id: string;
    vessel: string;
    voyage: string;
    closing?: Date;
    service: string;
    etd: Date;
    eta: Date;
    origin: SavedScheduleLocation;
    destination: SavedScheduleLocation;
    created_at: Date;
    available: boolean;
}
