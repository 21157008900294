import { Portals } from '@qwyk/models';

/**
 * Interface for the 'ShipmentAttachments' data
 */
export interface ShipmentAttachment {
    id: number;
    filename: string;
    description?: string;
    mime_type: string;
    extension: string;
    created_at: string;
    attachment_link: Portals.AttachmentLink | null;
}

export interface CreateShipmentAttachmentRequest {
    description: string;
    file: File;
}
