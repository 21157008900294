import {WarehouseEntity} from "./WarehouseEntity";

export interface PickupOrder extends WarehouseEntity {
    id: string;
    organization_id: number;
    number: string;
    status: number;
    status_name: string;
    supplier_name: string | null;
    shipper_name: string | null;
    consignee_name: string | null;
    pickup_entity_name: string | null;
    carrier_name: string | null;
    main_carrier_name: string | null;
    destination_agent_name: string | null;
    delivery_entity_name: string | null;
    issued_by_name: string | null;
    driver_name: string | null;
    driver_license_number: string | null;
    carrier_pro_number: string | null;
    carrier_tracking_number: string | null;
    supplier_po_number: string | null;
    supplier_invoice_number: string | null;
    destination_port_name: string | null;
    carrier_booking_number: string | null;
    notes: string | null;
    attachments_count: number;
    pickup_at: string | null;
    delivery_at: string | null;
    created_by: string | null;
    created_at: string | Date;
    updated_at: string | Date;
    deleted_at: string | Date;
}
