<ng-container [formGroup]="form">
    <div class="form-group" *ngIf="!hidden.includes('name')">
        <label for="name">{{ 'common.name' | translate }}</label>
        <input type="text" id="name" name="name" class="form-control" formControlName="name" maxlength="120"
            [ngClass]="{'is-invalid': form.get('name')?.invalid && form.get('name')?.touched}" placeholder="{{ 'common.name' | translate }}" />
        <arv-validation-messages for="name"></arv-validation-messages>
    </div>
    <div class="form-group" *ngIf="!hidden.includes('street')">
        <label for="street">{{ 'common.street' | translate }}</label>
        <input type="text" id="street" name="street" class="form-control" formControlName="street" maxlength="60"
            [ngClass]="{'is-invalid': form.get('street')?.invalid && form.get('street')?.touched}" placeholder="{{ 'common.street' | translate }}" />
        <arv-validation-messages for="street"></arv-validation-messages>
    </div>
    <div class="form-group">
        <input type="text" id="street_extra" name="street_extra" class="form-control" formControlName="street_extra"
            [ngClass]="{'is-invalid': form.get('street_extra')?.invalid && form.get('street_extra')?.touched}"
            maxlength="60" placeholder="{{ 'common.street' | translate }}" />
        <arv-validation-messages for="street_extra"></arv-validation-messages>
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label for="post_code">{{ 'portal.booking.place-selection-dialog.postal-code' | translate }}</label>
            <input type="text" id="post_code" name="post_code" class="form-control" formControlName="post_code"
                [ngClass]="{'is-invalid': form.get('post_code')?.invalid && form.get('post_code')?.touched}"
                maxlength="60" placeholder="{{ 'portal.booking.place-selection-dialog.postal-code' | translate }}" />
            <arv-validation-messages for="post_code"></arv-validation-messages>
        </div>
        <div class="form-group col-6">
            <label for="city">{{ 'hub.shipment.sections.instructions.subsections.parties-fields.city' | translate }}</label>
            <input type="text" id="city" name="city" class="form-control" formControlName="city" maxlength="60"
                [ngClass]="{'is-invalid': form.get('city')?.invalid && form.get('city')?.touched}" placeholder="{{ 'hub.shipment.sections.instructions.subsections.parties-fields.city' | translate }}" />
            <arv-validation-messages for="city"></arv-validation-messages>
        </div>
        <div class="form-group col">
            <label for="state">{{ 'hub.shipment.sections.instructions.subsections.parties-fields.state' | translate }}</label>
            <input type="text" id="state" name="state" class="form-control" formControlName="state" maxlength="60"
                [ngClass]="{'is-invalid': form.get('state')?.invalid && form.get('state')?.touched}"
                placeholder="{{ 'hub.shipment.sections.instructions.subsections.parties-fields.state' | translate }}" />
            <arv-validation-messages for="state"></arv-validation-messages>
        </div>
    </div>
    <div class="form-group">
        <label for="country_code">{{ 'common.country' | translate }}</label>
        <ng-select
            id="country_code"
            formControlName="country_code"
            (change)="onCountryChanged($event)"
            [items]="countries$ | async"
            [clearable]="false"
            [loading]="countriesLoading"
            bindValue="alpha_2_code" bindLabel="short_name"
            placeholder="{{ 'common.country' | translate }}"
            [ngClass]="{'is-invalid': form.get('country_code')?.invalid && form.get('country_code')?.touched}"
        >
        </ng-select>
        <arv-validation-messages for="country_code"></arv-validation-messages>
    </div>
</ng-container>
