export interface Site {
    id: number;
    endpoint_identifier: string;
    name: string;
    description: string;
    active: boolean;
    primary_color: string;
    secondary_color: string;
    success_color: string;
    info_color: string;
    warning_color: string;
    danger_color: string;
    light_color: string;
    dark_color: string;
    homepage_links_color: string;
    font_sans_serif: string;
    font_weight_normal: number;
    font_headers: string;
    font_weight_headings: number;
    logo_url: string;
    favicon_url: string;
    contrast_logo_url: string;
    homepage_hero_url: string;
    page_title: string;
    navbar_brand_title: string;
    mailing_from_name: string;
    mailing_company_name: string;
    notifications_reply_to_address: string;
    homepage_links: {
        header_label: string;
        links: any[];
    };
    more_info_link: string | null;
    ga_tracking_id: string;
    enable_cname: boolean;
    cname: string;
    alternative_homepage: string;
    terms_and_conditions_label: string;
    terms_and_conditions_url: string;
    privacy_policy_label: string;
    privacy_policy_url: string;
    cookie_policy_label: string;
    cookie_policy_url: string;
    booking_terms_url: string;

    new_landingpage_optin: boolean;
    new_landingpage_title_text: string | null;
    new_landingpage_teaser_text: string | null;
    show_rate_carrier_info: boolean;
    is_quotes_hide_schedules: boolean;
    is_quotes_quickbook_enabled: boolean;
    new_landingpage_show_navbar: boolean;
    new_landingpage_dark_navbar: boolean;
    landingpage_default_origin_type: 'port' | 'place';
    landingpage_default_destination_type: 'port' | 'place';
    landingpage_default_additionals: string[];
    landingpage_default_currency: string | null;
    landingpage_require_port_entry_for_door: boolean;
    landingpage_quote_expiration_leeway: number;
    landingpage_component_order: string[];
    injectable_scripts?: any[];
}
