export interface GooglePlacesAddress {
    formattedAddress: string;
    streetNumber: string;
    route: string;
    locality: string;
    postalCode: string;
    state: string;
    countryName: string;
    countryCode: string;
    position: { lat: number; lng: number };
    types: string[];
    code: string;
    placeId: string;
    plusCode: string;
}
