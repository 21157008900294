export interface Address {
    id: number;
    name: null | string;
    address_1: null | string;
    address_2: null;
    postal_code: null | string;
    city: null | string;
    state_code: null | string;
    state: null | string;
    country_code: null;
    country: null;
    locode: null;
    contact_name: null;
    phone_number: null | string;
    email_address: null;
    reference: null;
    remarks: null;
    position: null;
}
