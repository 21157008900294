import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    standalone: true,
    imports: [FontAwesomeModule],
    selector: 'qwyk-expand-collapse-button',
    templateUrl: './expand-collapse-button.component.html',
    styleUrls: ['./expand-collapse-button.component.scss'],
})
export class ExpandCollapseButtonComponent {
    @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
    @Input() iconSize: SizeProp;
    @Input() expanded = true;

    onClick() {
        this.clicked.emit();
    }
}
