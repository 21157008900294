import {Country} from '../masterdata';

export interface CustomerTeam {

    id: string;
    name: string;
    type: string;
    segment: string;
    street1: string;
    street2: string;
    postalcode: string;
    city: string;
    state: string;
    country_code: string;
    country: Country;
    notes: string;
    erp_system_id: string;
    default_shipment_owner_id: string;
    rms_named_accounts: string[];
    rms_customer_id: string | null;
    contract_number: string | null;
    controlling_company_code?: string | null;
    controlling_branch_code?: string | null;
    is_shared_team: boolean;
    account_manager_id: number | null;
    account_managers: CustomerTeamAccountManager[]
    account_manager_name: string | null;
    updated_at: Date;
    credentials: {
        id: number;
        credential_key: string;
        credential_name: string;
        credentials: any;
        revoking?: boolean;
    }[];
    parent_name: string | null;
    parent_id?: string | null;
}

export interface CustomerTeamAccountManager{
    id?: string;
    name?: string;
}
