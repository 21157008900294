export interface HubUser {
    id: number;
    name: string;
    email: string;
    sub: string;
    organization_id: number;
    organization_name: string;
    role_id: string;
    role_name: string;
    active: boolean;
    created_at: Date;
    updated_at: Date;
}

export interface HubUserProfile {
    id: number;
    user_id: number;
    job_title: string;
    location: string;
    language: string;
    timezone: string;
    measurements: string;
}
