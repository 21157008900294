export interface Credentials {
    email: string;
    password: string;
    organization_id?: number;
    site_id: number;
}

export interface Token {
    access_token: string;
    expires_in: number;
    token_type: string;
}

export interface User {
    id: string;
    name: string;
    email: string;
    organization_id: number;
    remember_token: null;
    created_at: string;
    updated_at: string;
    email_verified_at: string;
    registered_site_id: number;
    approved_at: string;
    approved_by_id: number;
    last_login_at: string;
    team_id: string;
    restrict_to_registered_site: number;
    has_shown_onboarding: number;
    location: string;
    job_title: string;
    language: string;
    units_of_measure: 'metric' | 'imperial';
    timezone: string;
    phone_number?: string;
    team?: any;
}
