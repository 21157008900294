export interface OrganizationFeature {
    feature_slug: string;
    string_value: string;
}

export interface Feature {
    feature_slug: string;
    id: number;
    name: string;
    value: string | number | boolean;
    value_type: string;
    organization_value: string | number | boolean;
    default_value: string | number | boolean;
}
