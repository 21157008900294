import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'split',
})
export class SplitPipe implements PipeTransform {
    transform(value: string, separator: string): unknown {
        if (!value || !separator) {
            return value;
        }
        try {
            return value.split(separator);
        } catch {
            return null;
        }
    }
}
