import { NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Portals } from '@qwyk/models';
import { MomentModule } from 'ngx-moment';

@Component({
    standalone: true,
    imports: [NgIf, NgClass, UpperCasePipe, MomentModule, NgbTooltipModule, TranslateModule, FontAwesomeModule],
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-shipment-containers-list-item]',
    templateUrl: './shipment-containers-list-item.component.html',
    styleUrls: ['./shipment-containers-list-item.component.scss'],
})
export class ShipmentContainersListItemComponent {
    @Input() shipmentContainer: Portals.ShipmentDataContainer;
}
