export interface GooglePlace {
    id: string;
    place_id: string;
    reference: string;
    types: string[];
    name: string;
    formatted_address: string;
    geometry: {
        location: { lat: number; lng: number };
        viewpower: {
            norteast: { lat: number; lng: number };
            southwest: { lat: number; lng: number };
        };
    };
    icon: string;
    photos: {
        height: number;
        width: number;
        html_attributions: string[];
        photo_reference: string;
    };
}
export interface GooglePlaceImage {
    _id: string;
    locode: string;
    image_url: string;
    data: GooglePlace;
    attribution: string;
}
