/**
 * Interface for the 'Notifications' data
 */
export interface NotificationsEntity {
    id: string; // Primary ID
    entity: {
        id: string;
        title: string;
        type: string;
    };
    subject: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata: any;
    notification_sub_type: string | null;
    notification_type: string;
    read: boolean;
    received_at: Date;
    is_broadcast: boolean;
}
