import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

import { MomentModule } from 'ngx-moment';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
    standalone: true,
    imports: [NgIf, NgbTooltipModule, MomentModule],
    selector: 'qwyk-relative-time-display',
    templateUrl: './relative-time-display.component.html',
    styleUrls: ['./relative-time-display.component.scss'],
})
export class RelativeTimeDisplayComponent {
    @Input() value?: string;
}
