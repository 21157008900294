export interface SiteConfig {
    design: SiteDesign;
    features: any;
    organization_id: number;
    organization_name: string;
    site_id: number;
    authentication_type: 'email' | 'username';
    is_magaya_network: boolean;
    magaya_ltm?: any;
    is_multi_ltm?: boolean;
    magaya_multi_ltm_collection? : MagayaLTM[];
}

export interface MagayaLTM {
    current_version: string;
    ltm_portal_name: string;
    network_id: string;
    recommended_version: string;
    required_version: string;
    satisfies_recommended: boolean;
    satisfies_required: boolean;
}

export interface SiteDesign {
    colors: SiteColors;
    fonts: SiteFonts;
    media: SiteMedia;
    titles: SiteTitles;
    links: SiteLinks;
    analytics: SiteAnalytics;
    legal: SiteLegal;
    landing_page: SiteLandingPage;
    injectable_scripts: SiteInjectableScript[];
}

export interface SiteColors {
    primary_color: string;
    secondary_color: string;
    success_color: string;
    info_color: string;
    warning_color: string;
    danger_color: string;
    light_color: string;
    dark_color: string;
    homepage_links_color: string;
}

export interface SiteFonts {
    font_sans_serif: string;
    font_weight_normal: number;
    font_headers: string;
    font_weight_headings: number;
}

export interface SiteMedia {
    logo_url_path: string;
    contrast_logo_url_path: string;
    favicon_url: string;
    homepage_hero_url: string;
}

export interface SiteTitles {
    page_title: string;
    navbar_brand: string;
}

export interface SiteLink {
    approve: boolean;
    label: string;
    url: string;
    tooltip: string | null;
}

export interface SiteLinks {
    homepage: {
        header_label: string | null;
        links: SiteLink[] | null;
    } | null;
    alternative_homepage: string | null;
    more_info_link: string | null;
}

export interface SiteAnalytics {
    ga_tracking_id?: string | null;
}

export interface SiteLegal {
    terms_and_conditions_label: string;
    terms_and_conditions_url: string;
    privacy_policy_label: string;
    privacy_policy_url: string;
    cookie_policy_label: string;
    cookie_policy_url: string;
    booking_terms_url: string;
}

export interface SiteLandingPage {
    optin: boolean;
    title_text: string;
    teaser_text: string;
    show_rate_carrier_info: boolean;
    is_quotes_hide_schedules: boolean;
    is_quotes_quickbook_enabled: boolean;
    new_landingpage_show_navbar: boolean;
    new_landingpage_dark_navbar: boolean;
    landingpage_default_origin_type: string;
    landingpage_default_destination_type: string;
    landingpage_default_additionals: string[];
    landingpage_default_currency: string | null;
    landingpage_require_port_entry_for_door: boolean;
    landingpage_quote_expiration_leeway: number;
    landingpage_component_order: string[];
}

export interface SiteInjectableScript {
    type: string;
    is_href: boolean;
    async?: boolean;
    defer?: boolean;
    script: string;
}
