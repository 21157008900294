<div [ngClass]="cardClass" class="card shadow-sm">
    <div [ngClass]="headerClass" class="card-header bg-white border-0 px-4">
        <h5 [ngClass]="headingClass" class="m-0">
            {{'portal.shipment.page.containers-list.containers' | translate}}
        </h5>
    </div>
    <ul class="list-group list-group-flush border-top-0">
        <li class="list-group-item px-4 py-2">
            <div class="row align-items-center">
                <div class="col-3">
                    {{'portal.shipment.page.containers-list.container' | translate}}
                </div>
                <div class="col-2 text-center">
                    {{'portal.shipment.page.containers-list.available-pickup' | translate}}
                </div>
                <div class="col-2 text-center">
                    {{'portal.shipment.page.containers-list.holds' | translate}}
                </div>
                <div class="col-2">
                    {{'portal.shipment.page.containers-list.location' | translate}}
                </div>
                <div class="col-3">
                    {{'portal.shipment.page.containers-list.last-free-pickup' | translate}}
                </div>
            </div>
        </li>
        <li
            *ngFor="let shipmentContainer of shipmentContainers"
            [shipmentContainer]="shipmentContainer"
            class="list-group-item p-4"
            qwyk-shipment-containers-list-item
        ></li>
        <li
            *ngIf="
                !loading && (!shipmentContainers || !shipmentContainers.length)
            "
            class="list-group-item p-4 text-center text-muted"
        >
            <fa-icon [icon]="['far', 'container-storage']" size="lg"></fa-icon>
            <p class="m-0">
                {{ 'common.shipment.no-containers-found' | translate }}
            </p>
        </li>
        <li *ngIf="loading" class="list-group-item p-4 text-center">
            <fa-icon
                [icon]="['far', 'spinner']"
                animation="spin-pulse"
                size="lg"
            ></fa-icon>
            <p class="m-0">
                {{'common.loading_items' | translate:{
                    value: 'portal.shipment.page.containers-list.containers' | translate | lowercase
                } }}
            </p>
        </li>
    </ul>
</div>
