import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LowerCasePipe } from '@angular/common';

import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ShipmentActivitiesListItemComponent } from '../shipment-activities-list-item/shipment-activities-list-item.component';

@Component({
    standalone: true,
    imports: [RouterLink, NgbTooltipModule, MomentModule, FontAwesomeModule, LowerCasePipe, TranslateModule],
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-shipment-activities-milestone-change]',
    templateUrl: './shipment-activities-milestone-change.component.html',
    styleUrls: ['./shipment-activities-milestone-change.component.scss'],
})
export class ShipmentActivitiesMilestoneChangeComponent extends ShipmentActivitiesListItemComponent {
    constructor() {
        super();
    }
}
