import {
    Input,
    Output,
    Component,
    OnChanges,
    OnDestroy,
    EventEmitter,
    SimpleChanges,
} from '@angular/core';
import { NgIf } from '@angular/common';

import { common, Portals } from '@qwyk/models';
import { PageRequest } from '@qwyk/ui-standalone';

import { GalleriaModule } from 'primeng/galleria';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    standalone: true,
    imports: [NgIf, GalleriaModule, FontAwesomeModule],
    selector: 'qwyk-attachments-carousel',
    templateUrl: './attachments-carousel.component.html',
    styleUrls: ['./attachments-carousel.component.scss'],
})
export class AttachmentsCarouselComponent implements OnChanges, OnDestroy {
    @Input() pagination: common.PaginationMeta;
    @Input() carouselPagination: common.PaginationMeta;
    @Input() currentIndex: number;
    @Input() attachmentLinks: Portals.Attachment[][];
    @Input() showCarousel: boolean;
    @Input() isLoading = false;
    @Input() currentPage: number;

    @Output() loadMore: EventEmitter<PageRequest> =
        new EventEmitter<PageRequest>();
    @Output() hideCarousel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saveData: EventEmitter<number> = new EventEmitter<number>();

    public lastIndex = 0;
    public responsiveOptions = {};
    public attachmentLinksResolved: Portals.Attachment[] = [];
    private _isLoadingInBackground = false;
    private _isInitialLoad = false;

    ngOnChanges(changes: SimpleChanges): void {
        this._isLoadingInBackground = !!(
            changes['attachmentLinks']?.currentValue &&
            changes['attachmentLinks']?.previousValue
        );

        this._isInitialLoad = changes['currentIndex']?.firstChange;

        if (this._isLoadingInBackground) {
            this.currentIndex = this.lastIndex;
        }

        if (this._isInitialLoad || this._isLoadingInBackground) {
            this.resolveAttachmentsCarousel();

            if (!this._isLoadingInBackground) {
                this.onActiveIndexChange(this.currentIndex);
            }
        }
    }

    ngOnDestroy(): void {
        this.attachmentLinks = null;
        this.attachmentLinksResolved = [];
        if (
            this.pagination?.current_page !==
            this.carouselPagination?.current_page
        ) {
            const page = this.pagination?.current_page;
            this.saveData.emit(page);
        }
        this.carouselPagination = null;
    }

    public resolveAttachmentsCarousel(): void {
        let currentPage = this.carouselPagination?.current_page ?? 1;

        if (this.currentPage && this._isInitialLoad) {
            currentPage = this.currentPage;
        } else if (this.currentPage && this._isLoadingInBackground) {
            currentPage = this.carouselPagination?.current_page;
        }

        if (this.attachmentLinks && this.attachmentLinks[currentPage]) {
            const nextSet: Portals.Attachment[] =
                this.attachmentLinks[currentPage];

            const currentItem =
                (this.attachmentLinksResolved &&
                    this.attachmentLinksResolved[this.currentIndex]) ||
                nextSet[this.currentIndex];

            const isGoingBackwards =
                this._isGoingBackwards(this.currentIndex) ||
                this.currentIndex === 0;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const isGoingForward =
                this._isGoingForward(this.currentIndex) ||
                this.currentIndex === 9;

            if (!this.attachmentLinksResolved.length) {
                this.attachmentLinksResolved = [...nextSet];
            } else if (isGoingBackwards) {
                this.attachmentLinksResolved = [
                    ...nextSet,
                    ...this.attachmentLinksResolved,
                ];
            } else {
                this.attachmentLinksResolved = [
                    ...this.attachmentLinksResolved,
                    ...nextSet,
                ];
            }

            this.attachmentLinksResolved = [
                ...this._removeDuplicatedAttachments(
                    this.attachmentLinksResolved
                ),
            ].map(item => ({
                ...item,
                isImage: ['jpg', 'jpeg', 'png', 'gif'].includes(
                    item.extension.toLowerCase()
                ),
            }));

            if (currentItem) {
                this.currentIndex = this.attachmentLinksResolved.findIndex(
                    i => i.id === currentItem.id
                );
            }
        }
    }

    public onVisibleChange(status) {
        this.hideCarousel.emit(!status);
    }

    public onActiveIndexChange(currentIndex: number): void {
        if (
            this._isLastItemOnPage(currentIndex) &&
            this._hasNextPage(currentIndex) &&
            (this._isGoingForward(currentIndex) || !this._isLoadingInBackground)
        ) {
            const pageRequest: PageRequest = {
                page: this.attachmentLinksResolved[currentIndex].page + 1,
                page_size: this.carouselPagination.per_page,
            };
            this.loadMore.emit(pageRequest);
        }

        if (
            this._isFirstItemOnPage(currentIndex) &&
            this._hasPrevPage(currentIndex) &&
            (this._isGoingBackwards(currentIndex) ||
                !this._isLoadingInBackground)
        ) {
            const pageRequest: PageRequest = {
                page: this.attachmentLinksResolved[currentIndex].page - 1,
                page_size: this.carouselPagination.per_page,
            };
            this.loadMore.emit(pageRequest);
        }
        this.lastIndex = currentIndex;
        this.currentIndex = currentIndex;
    }

    private _isGoingBackwards(currentIndex: number): boolean {
        return !!this.lastIndex && currentIndex < this.lastIndex;
    }

    private _isGoingForward(currentIndex: number): boolean {
        return !!this.lastIndex && currentIndex > this.lastIndex;
    }

    private _isLastItemOnPage(currentIndex: number): boolean {
        return this.attachmentLinksResolved[currentIndex].isLastItemOnPage;
    }

    private _isFirstItemOnPage(currentIndex: number): boolean {
        return this.attachmentLinksResolved[currentIndex].isFirstItemOnPage;
    }

    private _hasNextPage(currentIndex: number): boolean {
        return this.attachmentLinksResolved[currentIndex].hasNextPage;
    }

    private _hasPrevPage(currentIndex: number): boolean {
        return this.attachmentLinksResolved[currentIndex].hasPrevPage;
    }

    private _removeDuplicatedAttachments = (
        arr: Portals.Attachment[]
    ): Portals.Attachment[] => {
        const itemsMap = arr.map(attachment => {
            return [attachment.id, attachment];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any;

        return [...new Map(itemsMap).values()] as Portals.Attachment[];
    };
}
