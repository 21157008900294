import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '@qwyk/core';

@Pipe({
    standalone: true,
    name: 'productTitle',
})
export class ProductTitlePipe implements PipeTransform {
    private readonly _titles: { [index: string]: string } =
        Constants.PRODUCT_TITLES;

    transform(value: string): string {
        return this._titles[value];
    }
}
