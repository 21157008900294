import * as moment from 'moment';

export interface BillingRule {
    id: number;
    organization_id: number;
    organization_name: string;
    is_active: boolean;
    effective_from: Date | moment.Moment;
    effective_until: Date | null;
    type: string;
    description: string;
    quantity: number;
    currency_code: string;
    price: number;
    minimum: number | null;
    maximum: number | null;
}
