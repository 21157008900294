import { Pipe, PipeTransform } from '@angular/core';

import { Portals } from '@qwyk/models';

@Pipe({ standalone: true, name: 'multiUnitPipe' })
export class MultiUnitPipe implements PipeTransform {
    transform(
        value: Portals.MultiUnitValue,
        unit = 'metric'
    ): undefined | { value: number; unit: string } {
        if (!value) {
            return;
        }
        const unitValue = value[unit];
        if (!unitValue) {
            return;
        }
        return { value: unitValue.value, unit: unitValue.unit };
    }
}
