import { common } from '../../index';
import { Organization } from './Organization';

export interface MailingList {
    id: string;
    name: string;
    description: string;
    organization?: Organization;
    active?: boolean;
    members?: MailingListMember[] | null;
    membersPagination?: common.PaginationMeta | null;
    membersLoaded?: boolean | null;
    membersLoading?: boolean | null;
    membersError?: string | null;
    availableMembers?: MailingListMember[] | null;
    availableMembersPagination?: common.PaginationMeta | null;
    availableMembersLoaded?: boolean | null;
    availableMembersLoading?: boolean | null;
    availableMembersError?: string | null;
}

export interface MailingListMember {
    id: string;
    name?: string;
    email?: string;
    role?: string;
}

export interface MailingListMemberRole {
    id: string;
    name: string;
}

export interface MailingRule {
    id: string;
    name: string;
    template: NotificationType;
    active: boolean;
    default: boolean;
    created_at?: string | null;
    updated_at?: string | null;
    availableMailingLists?: MailingList[] | null;
    organization?: Organization | null;
    availableMailingListsPagination?: common.PaginationMeta | null;
    availableMailingListsLoaded?: boolean | null;
    availableMailingListsLoading?: boolean | null;
    availableMailingListsError?: string | null;
    selectedMailingLists?: MailingList[] | null;
    selectedMailingListsPagination?: common.PaginationMeta | null;
    selectedMailingListsLoaded?: boolean | null;
    selectedMailingListsLoading?: boolean | null;
    selectedMailingListsError?: string | null;

    availableHubUsers?: MailingListMember[] | null;
    availableHubUsersPagination?: common.PaginationMeta | null;
    availableHubUsersLoaded?: boolean | null;
    availableHubUsersLoading?: boolean | null;
    availableHubUsersError?: string | null;
    selectedHubUsers?: MailingListMember[] | null;
    selectedHubUsersPagination?: common.PaginationMeta | null;
    selectedHubUsersLoaded?: boolean | null;
    selectedHubUsersLoading?: boolean | null;
    selectedHubUsersError?: string | null;

    availableCustomers?: MailingListMember[] | null;
    availableCustomersPagination?: common.PaginationMeta | null;
    availableCustomersLoaded?: boolean | null;
    availableCustomersLoading?: boolean | null;
    availableCustomersError?: string | null;
    selectedCustomers?: MailingListMember[] | null;
    selectedCustomersPagination?: common.PaginationMeta | null;
    selectedCustomersLoaded?: boolean | null;
    selectedCustomersLoading?: boolean | null;
    selectedCustomersError?: string | null;
}

export interface NotificationType {
    id: number;
    template: string;
    template_class: string;
    customer_related: number;
}
