import { WarehouseEntity } from './WarehouseEntity';

export interface WarehouseReceipt extends WarehouseEntity {
    id: string;
    organization_id: number;
    number: string;
    status: number;
    status_name: string;
    warehouse: string | null;
    bonded_warehouse_type: string | null;
    bonded_warehouse_number: string | null;
    go_number: string | null;
    cfs_number: string | null;
    method: string | null;
    origin_name: string | null;
    origin_country: string | null;
    origin_code: string | null;
    destination_name: string | null;
    destination_country: string | null;
    destination_code: string | null;
    mode_of_transportation: string | null;
    shipper_name: string | null;
    consignee_name: string | null;
    supplier_name: string | null;
    destination_agent_name: string | null;
    issued_by_name: string | null;
    billing_client_name: string | null;
    division_name: string | null;
    driver_name: string | null;
    carrier_pro_number: string | null;
    carrier_tracking_number: string | null;
    driver_licence_number: string | null;
    supplier_po_number: string | null;
    supplier_invoice_number: string | null;
    notes: string | null;
    attachments_count: number;
    is_on_hold: boolean;
    on_hold_reason: string | null;
    created_by: string | null;
    customs_entry_at: string | null;
    last_event: any;
    custom_fields_dictionary: any;
    created_at: string | Date;
    updated_at: string | Date;
    deleted_at: string | Date | null;
    cargo_description: string | null;
    vin_numbers: string | null;
}

export interface CargoItemParent {
    type: string;
    id: string;
    number: string;
    url: string;
}

export interface CargoItem {
    id: string;
    status: number | null;
    status_name: string | null;
    type_code: number | null;
    count: number | null;
    quantity: number | null;
    package_name: string | null;
    description: string | null;
    location: string | null;
    part_number: string | null;
    serial_number: string | null;
    weight: MultiUnitValue | null;
    volume: MultiUnitValue | null;
    length: MultiUnitValue | null;
    width: MultiUnitValue | null;
    height: MultiUnitValue | null;
    total_value: {
        currency: string;
        amount: number;
    } | null;
    is_child: boolean | null;
    is_kit: boolean | null;
    is_container: boolean | null;
    is_really_a_container: boolean | null;
    is_vehicle: boolean | null;
    is_on_hold: boolean | null;
    is_by_total: boolean | null;
    is_dangerous: boolean | null;
    is_canceled: boolean | null;
    is_in_task: boolean | null;
    is_overstock: boolean | null;
    is_weight_calculated: boolean | null;
    lot_number: string | null;
    model: string | null;
    ncm_code: string | null;
    temperature_setup: string | null;
    temperature_setup_unit: number | null;
    external_tracking_numbers: string[] | null;
    supplier_po_number: string | null;
    supplier_invoice_number: string | null;
    notes: string | null;
    cargo_releases: CargoItemParent[];
    pickup_orders: CargoItemParent[];
    warehouse_receipts: CargoItemParent[];
    shipments: CargoItemParent[];
    schedule_b_code: string | null;
    schedule_b_description: string | null;
    location_description: string | null;
    include_eei: boolean | null;
    licence_type: string | null;
    export_code: string | null;
    pallet_id: string | null;
    whr_item_id: string | null;
    second_description: string | null;
    has_attachments: boolean | null;
    unit: string | null;
    ventilation_setup: string | null;
    commodity_type_name: string | null;
    harmonized_tariff: string | null;
    manufacturer: string | null;
    country: string | null;
    expiration_date: Date | string | null;
    house_way_bill_number: string | null;
    master_way_bill_number: string | null;
    hold_reason: string | null;
    entry_date: Date | string | null;
}

export interface Attachment {
    id: string;
    parent_type: string;
    parent_id: string;
    filename: string | null;
    description: string | null;
    mime_type: string | null;
    extension: string | null;
    attachment_link: AttachmentLink | null;
    created_at: string | Date | null;
    hasPrevPage: boolean | null;
    hasNextPage: boolean | null;
    isFirstItemOnPage: boolean | null;
    isLastItemOnPage: boolean | null;
    page: number | null;
}

export interface AttachmentLink {
    data: {
        url: string;
        valid_until: Date;
    };
}

export interface AttachmentRequest {
    description: string;
    file: File;
}

export interface Event {
    id: string;
    parent_type: string;
    parent_id: string;
    name: string | null;
    event: string | null;
    location_name: string | null;
    date: string | Date | null;
}

export interface UnitValue {
    value: number;
    unit: string;
}

export interface MultiUnitValue {
    [index: string]: UnitValue;
}

export interface Charge {
    id: string;
    parent_type: string;
    parent_id: string;
    type: number;
    service: string;
    description: string | null;
    quantity: number;
    price: Price;
    tax_percentage: number;
    retention: number;
    notes: string | null;
    is_voided: boolean;
    is_prepaid: boolean;
}

export interface Price {
    currency: string;
    amount: number;
}

export interface Entity {
    entity_type: string;
    parent_type: string;
    parent_id: string;
    magaya_customer: MagayaCustomer;
    name: string | null;
    street1: string | null;
    postalcode: string | null;
    city: string | null;
    state: string | null;
    country_code: string | null;
    country: string | null;
    contact_name: string | null;
    contact_phone: string | null;
    contact_email: string | null;
}

export interface MagayaCustomer {
    id: string;
    name: string;
    street1: string;
    state: string;
    city: string;
    postalCode: string;
    country: string;
}

export interface Email {
    to: string;
    cc: string | null;
    subject: string;
    body: string;
}
