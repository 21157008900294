import { UserSummary } from '../auth';

export interface Invoice {
    id: string;
    invoice_number: string;
    bill_to_address: string;
    issued_date: Date;
    customer: UserSummary;
    invoicable_type_short: string;
    invoicable_name: string;
    invoicable_type: string;
    invoicable_id: string | number;
    currency: string;
    total_amount: number;
    is_proforma: boolean;
    payment_term_days: number | null;
    payment_terms: string | null;
    remarks: string | null;
    invoice_lines: InvoiceLine[];
    created_at: Date;
}

export interface InvoiceLine {
    id: string;
    charge_code: string;
    currency: string;
    description: string;
    quantity: number;
    price: number;
    total_amount: number;
    exchange_rate: number;
    total_amount_invoice: number;
}
