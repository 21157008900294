import {WarehouseEntity} from "./WarehouseEntity";

export interface CargoRelease extends WarehouseEntity {
    id: string;
    organization_id: number;
    number: string;
    status: number;
    status_name: string;
    carrier_name: string | null;
    issued_by_name: string | null;
    released_to_name: string | null;
    driver_name: string | null;
    driver_license_number: string | null;
    carrier_pro_number: string | null;
    carrier_tracking_number: string | null;
    supplier_po_number: string | null;
    notes: string | null;
    attachments_count: number;
    released_at: string | Date;
    created_by: string | null;
    created_at: string | Date;
    updated_at: string | Date;
    deleted_at: string | Date;
}

export interface ProofOfDelivery{
    id: string;
    parent_type: string;
    received_by_name: string| null;
    comments: string | null;
    delivered_at: string | Date | null;
    signature: string | null;
}
