export interface IntegrationTemplate {
    id: number;
    transport_type: string;
    connection_type: string;
    connection_name: string;
    description: string;
    provider: string;
    job: string | null;
    webhook_endpoint: string | null;
    content_type: string | null;
    config: any;
    is_hidden: boolean;
}
