import { Country } from '../masterdata';

export interface UserSummary {
    id: string | number;
    type: string;
    name: string;
    email: string;
    team_id: null | number;
    team_name: null | string;
    job_title: null | string;
    company: null | string;
    location: null | string;
    phone_number: null | string;
    country: Country | null;
}
