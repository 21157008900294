export interface OrganizationConnection {
    id: number;
    organization_id: number;
    organization_name: string;
    direction: string;
    connection_type: string;
    connection_name: string;
    provider: string;
    config: any;
    webhook_token: string | null;
    job: string;
    active: boolean;
    content_type: string | null;
    webhook_endpoint: string | null;
    integration_template_id: number | null;
}
